import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss']
})
export class CompanyComponent implements OnInit {

  @ViewChild("scrolldownTarget") scrolldownTarget: ElementRef;

  constructor() { }

  ngOnInit() {
  }

  scrollDown() {
    this.scrolldownTarget.nativeElement.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  }

}
