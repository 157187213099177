import { Component, OnInit, HostListener } from '@angular/core';

interface Item {
  title:string;
  link:string;
}

@Component({
  selector: 'bhr-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  items: Item[] = [
    { title: 'Company', link: 'company' },
    { title: 'Career', link: 'career' },
    { title: 'Contact', link: 'contact' },
    { title: 'Impressum', link: 'impressum' },
  ]

  public isScrolled = false;

  constructor() { }

  ngOnInit() {
  }

  @HostListener('window:scroll', [])
  onScroll(): void {
    this.isScrolled = (window.scrollY >= 10);
  }

}
