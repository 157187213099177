import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CareerComponent } from './page/career/career.component';
import { CompanyComponent } from './page/company/company.component';
import { ImpressumComponent } from './page/impressum/impressum.component';
import { ContactComponent } from './page/contact/contact.component';

const routes: Routes = [
  {
    path: '', component: CompanyComponent, pathMatch: 'full',
  },
  {
    path: 'company', component: CompanyComponent,
  },
  {
    path: 'blog',
    loadChildren: () => import('./blog/blog.module').then(m => m.BlogModule)
  },
  {
    path: 'career', component: CareerComponent,
  },
  {
    path: 'contact', component: ContactComponent,
  },
  {
    path: 'impressum', component: ImpressumComponent,
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
