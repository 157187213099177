// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.inputlabels {
  font-size: 14pt;
  padding-right: 20px;
}

input[type=text], textarea {
  width: 30%;
  min-width: 250px;
  max-width: 500px;
}`, "",{"version":3,"sources":["webpack://./src/app/page/contact/contact.component.scss"],"names":[],"mappings":"AAAA;EACC,eAAA;EACA,mBAAA;AACD;;AAEA;EACC,UAAA;EACA,gBAAA;EACA,gBAAA;AACD","sourcesContent":[".inputlabels {\n\tfont-size: 14pt;\n\tpadding-right: 20px;\n}\n\ninput[type=\"text\"],textarea {\n\twidth: 30%;\n\tmin-width:250px;\n\tmax-width:500px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
