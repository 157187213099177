// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  width: 100%;
  background-color: #009cd2;
  color: #fff;
}
:host h1, :host h2, :host h3, :host h4 {
  color: #fff;
}

.footer-pad {
  background-color: #009cd2;
  padding-bottom: 120px;
  padding-top: 48px;
}

.footer-tp {
  padding-top: 36px;
  text-align: center;
}

hr {
  border: 1px solid rgba(255, 255, 255, 0.2);
}

a {
  display: block;
  font-size: 24px;
  color: #fff;
}

.credit {
  font-size: 14px;
  display: block;
  padding-top: 12px;
}
.credit a {
  font-size: 14px;
}

.flex2 {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  min-width: 6em;
}

.flex2 > div {
  margin-bottom: 2rem;
}

@media screen and (min-width: 769px) {
  .flex2 {
    flex-direction: row;
    min-width: initial;
  }
  .flex2 > div {
    margin-bottom: 0px;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/page/footer/footer.component.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,yBAAA;EAEA,WAAA;AAAJ;AAEI;EACI,WAAA;AAAR;;AAIA;EACI,yBAAA;EAEA,qBAAA;EACA,iBAAA;AAFJ;;AAKA;EACI,iBAAA;EACA,kBAAA;AAFJ;;AAKA;EACI,0CAAA;AAFJ;;AAMA;EACI,cAAA;EACA,eAAA;EACA,WAAA;AAHJ;;AAMA;EAKI,eAAA;EACA,cAAA;EACA,iBAAA;AAPJ;AACI;EACI,eAAA;AACR;;AAOA;EACI,aAAA;EACA,sBAAA;EACA,6BAAA;EACA,cAAA;AAJJ;;AAOA;EACI,mBAAA;AAJJ;;AAOA;EACI;IACI,mBAAA;IACA,kBAAA;EAJN;EAOE;IACI,kBAAA;EALN;AACF","sourcesContent":[":host {\n    width: 100%;\n    background-color: #009cd2;\n\n    color: #fff;\n\n    h1, h2, h3, h4 {\n        color: #fff;\n    }\n}\n\n.footer-pad {\n    background-color: #009cd2;\n\n    padding-bottom: 120px;\n    padding-top: 48px;\n}\n\n.footer-tp {\n    padding-top: 36px;\n    text-align: center;\n}\n\nhr {\n    border: 1px solid rgba(255,255,255,0.2);\n}\n\n\na {\n    display: block;\n    font-size: 24px;\n    color: #fff;\n}\n\n.credit {\n    a {\n        font-size: 14px;\n    }\n\n    font-size: 14px;\n    display: block;\n    padding-top: 12px;\n}\n\n.flex2 {\n    display: flex;\n    flex-direction: column;\n    justify-content: space-around;\n    min-width: 6em;\n}\n\n.flex2 > div {\n    margin-bottom: 2rem;\n}\n\n@media screen and (min-width: 769px) {\n    .flex2 {\n        flex-direction: row;\n        min-width: initial;\n    }\n\n    .flex2 > div {\n        margin-bottom: 0px;\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
